import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

const CodesTracker = () => {
  const [refetch, setRefetch] = useState(false);
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [trackCodesSuccess, trackCodesErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_CODE_TRACKER_API,
    "POST",
    submitGenerate.flag,
    submitGenerate.dependency,
    submitGenerate.data,
    true
  );
  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "generateCodesRequest",
      dependency: !submitGenerate.dependency,
      data: data,
    });
  };

  useEffect(() => {
    //! reset the form on success submit

    if (trackCodesSuccess) {
      reset();
      setRefetch(!refetch);
    }
  }, [trackCodesSuccess]);
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="الطلاب | استاذ طارق الخطيب"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-end text-4xl font-bold md:text-center">
          تتبع الكود
        </h2>

        <div className="w-full form-codes-container">
          <h2 className="my-20 text-center text-3xl font-semibold">
            من فضلك قم بملئ البيانات لتتبع الكود
          </h2>
          {trackCodesErrors && (
            <p className={`text-center font-bold mb-6 text-accent`}>
              {trackCodesErrors}
            </p>
          )}
          {trackCodesSuccess?.message && (
            <p className={`text-center font-bold mb-6 text-secondary `}>
              {trackCodesSuccess?.message}
            </p>
          )}
          <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start ">
            <form
              method="post"
              onSubmit={handleSubmit(onSubmit)}
              className="flex h-fit w-[380px] md:w-full flex-col  items-center justify-start gap-6 rounded-3xl bg-accent text-white p-10 shadow-lg shadow-red-200/30 md:w-[250px] "
            >
              <div className="w-full">
                {/** seryal code field */}
                <div className=" flex w-full flex-col items-end justify-center gap-2 md:w-full">
                  <label className="w-full truncate" htmlFor="code_id">
                    الكود
                  </label>
                  <input
                    className="signin-inputs w-full "
                    type="number"
                    min={1}
                    id="code_id"
                    placeholder="000"
                    name="code_id"
                    autoComplete="on"
                    {...register("code_id", {
                      required: true,
                      valueAsNumber: true,
                      min: 1,
                    })}
                  />

                  {errors.code_id && (
                    <p className="text-xl text-white ">
                      {errors.code_id.type === "required" &&
                        "هذه الخانة مطلوبه."}
                      {errors.code_id.type === "max" &&
                        "Max letters count is 255 letter"}
                      {errors.code_id.type === "min" &&
                        "Please enter at least one letter"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    trackCodesErrors &&
                      trackCodesErrors?.response?.data?.errors?.code_id && (
                        <p className="w-full text-end text-xs text-white  ">
                          {trackCodesErrors?.response?.data?.errors?.code_id[0]}
                        </p>
                      )
                  }
                </div>
              </div>
              {/** submit */}
              <button
                disabled={!isValid || (submitLoading && submitGenerate.flag)}
                className=" submit mt-6 w-full "
                type="submit"
              >
                {submitLoading && submitGenerate.flag ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "تتبع"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CodesTracker;
