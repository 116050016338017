//!------edit in table ------

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditCategory() {
  const navigate = useNavigate();
  const [submitCategory, setSubmitCategory] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [addCategorySuccess, addCategoryErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADD_CATEGORY_API,
    "POST",
    submitCategory.flag,
    submitCategory.dependency,
    submitCategory.data,
    true
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitCategory({
      flag: "addCategoryRequest",
      dependency: !submitCategory.dependency,
      data: data,
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addCategorySuccess) {
      reset();
      navigate(-1);
    }
  }, [addCategorySuccess]);
  return (
    <section className="flex h-full w-full flex-col items-center dark:bg-dark dark:text-light">
      <HelmetTags title="الاصناف | استاذ طارق الخطيب"></HelmetTags>
      <div className="form-container my-20 flex flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          من فضلك قم بملئ البيانات لإضافة التصنيف
        </h2>

        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-fit w-[380px] md:w-full flex-col  items-center justify-start gap-6 rounded-3xl bg-accent text-white p-10 shadow-lg shadow-red-200/30 md:w-[250px] "
        >
          {/** Category name */}

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate" htmlFor="name">
              اسم التصنيف{" "}
            </label>
            <input
              className="signin-inputs  w-full "
              type="text"
              id="name"
              placeholder="أدخل اسم التصنيف"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                maxLength: 100,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className="text-[12px] text-white ">
                {errors.name.type === "required" && "برجاء ملئ هذا الحقل"}{" "}
                {errors.name.type === "maxLength" &&
                  "أقصي عدد للحروف هو ١٠٠ حرف"}
                {errors.name.type === "minLength" &&
                  " من فضلك فم بكتابة ٣ أحرف علي الاقل"}
              </p>
            )}
            {
              //!-------server errors -----

              addCategoryErrors &&
                addCategoryErrors?.response?.data?.errors?.name && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {addCategoryErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>

          {/** stage*/}
          <div
            className={`flex w-full flex-col items-end justify-center  gap-2 `}
          >
            <label className="w-full truncate" htmlFor="stage">
              الصف الدراسى{" "}
            </label>
            <select
              name="stage"
              id="stage"
              {...register("stage", { required: true })}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>

            {errors.stage && (
              <p className="text-[12px] text-white ">
                {errors.stage.type === "required" && "برجاء ملئ هذا الحقل"}{" "}
              </p>
            )}
            {
              //!-------server errors -----

              addCategoryErrors &&
                addCategoryErrors?.response?.data?.errors?.stage && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {addCategoryErrors?.response?.data?.errors?.stage[0]}
                  </p>
                )
            }
          </div>

          {/** submit */}

          <button
            disabled={!isValid || (submitLoading && submitCategory.flag)}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && submitCategory.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "اضافة التصنيف"
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditCategory;
