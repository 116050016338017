import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditEssayQuestion({
  edit,
  type,
  id,
  lectureQuestion,
  refetch,
  setRefetch,
}) {
  const { categoryID, questionID } = useParams();
  const [question, setQuestion] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [degree, setDegree] = useState("");
  const [errors, setErrors] = useState("");

  const navigate = useNavigate();

  const [submitQuestion, setSubmitQuestion] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!  reset error messages if the input value has changed

  useEffect(() => {
    if (question !== "") {
      setErrors("");
    } else if (degree !== "") {
      setErrors("");
    } else if (difficulty !== "") {
      setErrors("");
    }
  }, [question, difficulty, degree]);

  //!---add edit question request
  // let api =
  //   type === "homework"
  //     ? process.env.REACT_APP_ADMIN_ADD_HOMEWORK_ESSAY_QUESTIONS_API
  //     : type === "quiz"
  //     ? process.env.REACT_APP_ADMIN_ADD_QUIZ_ESSAY_QUESTIONS_API
  //     : edit
  //     ? process.env.REACT_APP_EDIT_QUESTION_API
  //     : `${process.env.REACT_APP_ADD_ESSAY_QUESTION_API}${categoryID}`;

  let api =
    type === "homework"
      ? process.env.REACT_APP_ADMIN_ADD_HOMEWORK_ESSAY_QUESTIONS_API
      : type === "quiz"
      ? process.env.REACT_APP_ADMIN_ADD_QUIZ_ESSAY_QUESTIONS_API
      : type === "exam"
      ? process.env.REACT_APP_ADMIN_TRIAL_EXAM_ADD_ESSAY_QUESTION_API
      : edit
      ? process.env.REACT_APP_EDIT_QUESTION_API
      : `${process.env.REACT_APP_ADD_ESSAY_QUESTION_API}${categoryID}`;

  const [addEditQuestionSuccess, addEditQuestionErrors, submitLoading] =
    useAxios(
      api,
      "POST",
      submitQuestion.flag,
      submitQuestion.dependency,
      submitQuestion.data,
      true
    );

  //!---get question data request
  let QuestionDataApi = lectureQuestion
    ? `${process.env.REACT_APP_QUIZ_HOMEWORK_QUESTION_INFO_API}${questionID}`
    : `${process.env.REACT_APP_QUESTION_INFO_API}${questionID}`;

  const [QuestionDataSuccess, QuestionErrors] = useAxios(
    QuestionDataApi,
    "GET",
    edit
  );
  useEffect(() => {
    if (QuestionDataSuccess) {
      setDifficulty(QuestionDataSuccess.data.difficulty);
      setQuestion(QuestionDataSuccess.data.question);
      setDegree(QuestionDataSuccess.data?.degree);
    }
  }, [QuestionDataSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (question === "") {
      setErrors("question");
      toast.error("برجاء ملئ جميع البيانات المطلوبة");
    } else if (difficulty === "" && !type) {
      setErrors("difficulty");
      toast.error("برجاء ملئ جميع البيانات المطلوبة");
    } else if (degree === "" || Number(degree) < 1) {
      setErrors("degree");
      toast.error("من فضلك ادخل رقم اكبر من صفر");
    } else {
      const data =
        type === "homework"
          ? {
              question: question,
              degree: degree,
              homework_id: id,
            }
          : type === "quiz"
          ? {
              question: question,
              degree: degree,
              quiz_id: id,
            }
          : type === "exam"
          ? {
              question: question,
              degree: degree,
              exam_id: id,
            }
          : {
              question: question,
              difficulty: difficulty,
              degree: degree,
              id: edit ? questionID : categoryID,
            };
      setSubmitQuestion({
        flag: "addEditQuestionRequest",
        dependency: !submitQuestion.dependency,
        data: data,
      });
    }
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addEditQuestionSuccess) {
      setQuestion("");
      setDifficulty("");
      setDegree("");

      setErrors("");
      type ? setRefetch(!refetch) : navigate(-1);
    }
  }, [addEditQuestionSuccess]);

  return (
    <section className="flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light">
      {!type && (
        <HelmetTags title="بنك الاسئلة | استاذ طارق الخطيب"></HelmetTags>
      )}
      <form
        method="post"
        onSubmit={handleSubmit}
        className="form-container my-20 flex flex-col items-center bg-accent text-white rounded-3xl p-8"
      >
        <h2 className="my-20 text-center text-3xl font-bold">
          {edit
            ? "من فضلك قم بملئ البيانات لتعديل السؤال "
            : "من فضلك قم بملئ البيانات لإضافة السؤال"}
        </h2>
        {/**
         * //!------question-----
         */}
        <div className=" question mb-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-3xl font-semibold">السؤال</h2>
          <div className="text-black">
            <CKEditor
              config={{
                toolbar: {
                  items: [
                    "undo",
                    "redo",
                    "|",
                    "imageInsert",
                    "|",
                    "heading",
                    "bold",
                    "italic",
                    "strikethrough",
                    "underline",
                    "link",
                    "blockQuote",
                    "removeFormat",
                    "|",
                    "selectAll",
                    "specialCharacters",
                    "superscript",
                    "subscript",
                    "style",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "outdent",
                    "indent",
                    "|",
                    "mediaEmbed",
                    "insertTable",
                    "|",
                    "fontFamily",
                    "fontColor",
                    "fontBackgroundColor",
                    "fontSize",
                    "highlight",
                    "|",
                    "horizontalLine",
                    "pageBreak",
                    "findAndReplace",
                    "restrictedEditingException",
                    "textPartLanguage",
                    "-",
                  ],
                  shouldNotGroupWhenFull: true,
                },
                language: "ar",
                image: {
                  toolbar: [
                    "imageTextAlternative",
                    "toggleImageCaption",
                    "imageStyle:inline",
                    "imageStyle:block",
                    "imageStyle:side",
                  ],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "tableCellProperties",
                    "tableProperties",
                  ],
                },
              }}
              editor={ClassicEditor}
              data={question}
              onChange={(event, editor) => {
                const data = editor.getData();
                setQuestion(data);
              }}
            />
            {errors === "question" && (
              <p className="text-[12px] text-white ">برجاء ملئ هذا الحقل</p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.question && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {addEditQuestionErrors?.response?.data?.errors?.question[0]}
                  </p>
                )
            }
          </div>
        </div>
        {/**
         * //!------Difficulty degree -----
         */}{" "}
        <div className="flex w-full items-start justify-evenly gap-10 md:flex-col-reverse my-10">
          {!type && !lectureQuestion && (
            <div
              className={` flex w-1/3 flex-col items-end justify-center gap-2  md:w-full `}
            >
              <label className="w-full truncate" htmlFor="difficulty">
                المستوي{" "}
              </label>
              <select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                className="w-80"
                name="difficulty"
                id="difficulty"
              >
                <option hidden disabled value="">
                  المستوي
                </option>{" "}
                <option value="easy">سهل</option>
                <option value="medium">متوسط</option>
                <option value="hard">صعب</option>
              </select>

              {errors === "difficulty" && (
                <p className="text-[12px] text-white ">برجاء ملئ هذا الحقل</p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.difficulty && (
                    <p className="w-full text-end text-[12px] text-white  ">
                      {
                        addEditQuestionErrors?.response?.data?.errors
                          ?.difficulty[0]
                      }
                    </p>
                  )
              }
            </div>
          )}
          <div className=" Degree flex w-1/3  flex-col items-end justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="degree">
              الدرجة
            </label>
            <input
              className="signin-inputs w-full "
              type="number"
              inputMode="numeric"
              min={1}
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
              id="degree"
              placeholder="000"
              name="degree"
              autoComplete="on"
            />

            {errors === "degree" && (
              <p className="text-[12px] text-white ">برجاء ملئ هذا الحقل</p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.degree && (
                  <p className="text-[12px] text-white ">
                    {addEditQuestionErrors?.response?.data?.errors?.degree[0]}
                  </p>
                )
            }
          </div>
        </div>
        <button
          disabled={submitLoading && submitQuestion.flag}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {submitLoading && submitQuestion.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "تعديل السؤال" : "اضافة السؤال"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditEssayQuestion;
