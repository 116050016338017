import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import HelmetTags from "../../../../MainComponents/HelmetTags";

const AdminQuestionCorrectionForm = () => {
  const [content, setContent] = useState("");
  const { studentID, correctionType, questionId } = useParams();

  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddEditChapter": {
        return {
          ...state,
          submitAddEditChapter: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditChapter: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [
    QuestionCorrectionSuccess,
    QuestionCorrectionErrors,
    QuestionCorrectionLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_QUESTION_CORRECTION_API,
    "POST",
    state.submitAddEditChapter.flag,
    state.submitAddEditChapter.dependency,
    state.submitAddEditChapter.data,
    true
  );

  useEffect(() => {
    if (QuestionCorrectionSuccess) {
      navigate(-1);
      reset();
    }
  }, [QuestionCorrectionSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      id: questionId,
      comment: content,
    };

    dispatch({
      type: "setSubmitAddEditChapter",
      payload: {
        flag: "AddEditChapter",
        dependency: !state.submitAddEditChapter.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="التصحيح | استاذ طارق الخطيب"></HelmetTags>
      <form
        encType="multipart/form-data"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="flex   flex-col items-start justify-center gap-10 rounded-3xl bg-accent text-white p-16 shadow-lg shadow-red-200/50"
      >
        <div className=" flex w-full flex-col items-end justify-center gap-6 ">
          <div
            className={`flex w-full flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="degree">
              الدرجة{" "}
            </label>
            <input
              className="signin-inputs w-full pl-4    "
              type="number"
              id="degree"
              placeholder="الدرجة"
              name="degree"
              autoComplete="on"
              step="any"
              {...register("degree", {
                required: true,
                maxLength: 40,
                minLength: 1,
              })}
            />
            {errors.degree && (
              <p className="w-full text-end text-[12px] text-white  ">
                {errors.degree.type === "required" && "برجاء ملئ هذا الحقل"}
                {errors.degree.type === "maxLength" &&
                  "أقصي عدد للحروف هو ٤٠ حرف"}
                {errors.degree.type === "minLength" &&
                  "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
              </p>
            )}

            {
              //!-------server errors -----

              QuestionCorrectionErrors &&
                QuestionCorrectionErrors?.response?.data?.errors?.degree && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {
                      QuestionCorrectionErrors?.response?.data?.errors
                        ?.degree[0]
                    }
                  </p>
                )
            }
          </div>
          <div className="text-black content mb-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px]  sm:w-[300px]">
            <h2 className="text-white mb-3 font-semibold">التعليق</h2>
            <CKEditor
              config={{
                toolbar: {
                  items: [
                    "undo",
                    "redo",
                    "|",
                    "imageInsert",
                    "|",
                    "heading",
                    "bold",
                    "italic",
                    "strikethrough",
                    "underline",
                    "link",
                    "blockQuote",
                    "removeFormat",
                    "|",
                    "selectAll",
                    "specialCharacters",
                    "superscript",
                    "subscript",
                    "style",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "outdent",
                    "indent",
                    "|",
                    "mediaEmbed",
                    "insertTable",
                    "|",
                    "fontFamily",
                    "fontColor",
                    "fontBackgroundColor",
                    "fontSize",
                    "highlight",
                    "|",
                    "horizontalLine",
                    "pageBreak",
                    "findAndReplace",
                    "restrictedEditingException",
                    "textPartLanguage",
                    "-",
                  ],
                  shouldNotGroupWhenFull: true,
                },
                language: "en",
                image: {
                  toolbar: [
                    "imageTextAlternative",
                    "toggleImageCaption",
                    "imageStyle:inline",
                    "imageStyle:block",
                    "imageStyle:side",
                  ],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "tableCellProperties",
                    "tableProperties",
                  ],
                },
              }}
              editor={ClassicEditor}
              data={content}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
            {errors === "content" && (
              <p className="w-full text-end text-[12px] text-white  ">
                {" "}
                برجاء ملئ هذا الحقل.{" "}
              </p>
            )}
            {
              //!-------server errors -----

              QuestionCorrectionErrors &&
                QuestionCorrectionErrors?.response?.data?.errors?.content && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {
                      QuestionCorrectionErrors?.response?.data?.errors
                        ?.content[0]
                    }
                  </p>
                )
            }
          </div>
        </div>
        {/** submit */}

        <button
          disabled={
            !isValid ||
            (QuestionCorrectionLoading && state.submitAddEditChapter.flag)
          }
          className=" submit mt-6 w-full "
          type="submit"
        >
          {QuestionCorrectionLoading && state.submitAddEditChapter.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p>تصحيح السؤال</p>
          )}
        </button>
      </form>
    </section>
  );
};

export default AdminQuestionCorrectionForm;
