import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faUser,
  faGraduationCap,
  faGear,
  faStar,
  faArrowRightFromBracket,
  faQrcode,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "./GlobalContext.jsx";

function DropDownMenu({ scroll }) {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  function handleSignOut() {
    setShowMenu(false);

    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate("/signin");
  }
  return (
    <>
      {showMenu && (
        <div
          className="absolute inset-0 h-[100vh] w-[100vw]"
          onClick={() => setShowMenu(false)}
        ></div>
      )}
      <div
        className=" img-icon group relative  flex min-h-[] min-w-[] items-center justify-center gap-2 py-8"
        onClick={() => setShowMenu(!showMenu)}
      >
        <div
          className={`user-img-container h-12 min-h-[48px] w-12 min-w-[48px] overflow-hidden rounded-full border-2 p-1 transition-all duration-300 ease-in-out  ${
            scroll ? "border-light " : "border-secondary"
          } flex cursor-pointer items-center justify-center`}
        >
          <img
            src={`https://api.tarekelkhateeb.com/uploads/profile_picture/${AuthState.userData?.student?.profile_pic}`}
            alt="user-img"
            className="user-img w-full h-full rounded-full object-cover"
          />
        </div>
        <FontAwesomeIcon
          icon={faAngleDown}
          className={`cursor-pointer text-3xl transition-all duration-300 ease-in-out ${
            scroll ? "text-light " : "text-secondary"
          }  `}
        />

        <div
          className={`pointer-events-none absolute right-0 top-32 h-fit w-fit scale-90 opacity-0 transition-all duration-300 ease-in-out group-hover:pointer-events-auto group-hover:scale-100 group-hover:opacity-100 ${
            showMenu && "pointer-events-auto scale-100 opacity-100"
          }`}
        >
          <ul className="drop-menu-items   z-[150] flex h-[400px] w-96 flex-col items-end justify-start gap-10 rounded-xl border-2 border-secondary bg-light p-8 text-secondary shadow-md dark:border-2  dark:border-light dark:bg-dark dark:text-light">
            {/** 
            <li
              onClick={() => {
                navigate("");
                setShowMenu(false);
              }}
              className="flex cursor-pointer items-center justify-start gap-4 hover:opacity-80 "
            >
              <FontAwesomeIcon
                icon={faUser}
                className=" cursor-pointer text-2xl"
              />
              <p className="text-2xl font-semibold">Profile</p>
            </li>

            <li
              onClick={() => {
                navigate("/student/codes");
                setShowMenu(false);
              }}
              className="flex cursor-pointer items-center justify-start gap-4 hover:opacity-80"
            >
              <FontAwesomeIcon
                className=" cursor-pointer text-2xl"
                icon={faQrcode}
              />

              <p className="text-2xl font-semibold">Code History</p>
            </li>

            <li
              onClick={() => {
                navigate("/student/exams");
                setShowMenu(false);
              }}
              className="flex cursor-pointer items-center justify-start gap-4 hover:opacity-80"
            >
              <FontAwesomeIcon
                className=" cursor-pointer text-2xl"
                icon={faFileLines}
              />
              <p className="text-2xl font-semibold">Trial Exam History</p>
            </li>
 * 
 * 

 <li
   onClick={() => {
     navigate("");
     setShowMenu(false);
   }}
   className="flex justify-start items-center gap-4 cursor-pointer hover:opacity-80"
 >
   <FontAwesomeIcon
     icon={faStar}
     className=" text-2xl cursor-pointer"
   />
   <p className="text-2xl font-semibold">Go Pro</p>
 </li>
 * 
*/}

            <li
              onClick={() => {
                navigate("/home/profile");
                setShowMenu(false);
              }}
              className="flex cursor-pointer items-center justify-start gap-4 hover:opacity-80"
            >
              <p className="text-2xl font-semibold">الملف الشخصي</p>
              <FontAwesomeIcon
                icon={faUser}
                className="cursor-pointer text-2xl"
              />
            </li>
            <li
              onClick={() => handleSignOut()}
              className="flex cursor-pointer items-center justify-start gap-4 hover:opacity-80"
            >
              <p className="text-2xl font-semibold">تسجيل الخروج</p>
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className=" cursor-pointer text-2xl"
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default DropDownMenu;
