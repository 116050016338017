import { Link, useParams } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";

function CardIdForm({ studentData, setStudentData }) {
  const { lectureID, groupID } = useParams();
  const idCard = useRef(null);
  const [errors, setErrors] = useState("");
  const [attendByCardID, setAttendByCardID] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [attendByCardIDSuccess, attendByCardIDErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ATTEND_STUDENT_API,
    "POST",
    attendByCardID.flag,
    attendByCardID.dependency,
    attendByCardID.data,
    true
  );
  useEffect(() => {
    idCard?.current?.focus();
    if (attendByCardIDSuccess) {
      setErrors("");
      idCard.current.value = "";
      setStudentData(attendByCardIDSuccess.data);
    }
    if (attendByCardIDErrors) {
      setErrors("");
    }
  }, [attendByCardIDSuccess, idCard, studentData, attendByCardIDErrors]);

  function onSubmit(e) {
    e.preventDefault();
    if (idCard?.current?.value === "") {
      setErrors("برجاء ملئ هذا الحقل");
    } else {
      setAttendByCardID({
        flag: "attendByCardID",
        dependency: !attendByCardID.dependency,
        data: {
          value: idCard?.current?.value,
          type: "magnetic_id",
          group_id: groupID,
          lecture_id: lectureID,
        },
      });
    }
  }
  return (
    <form
      onSubmit={onSubmit}
      method="post"
      className="flex w-[450px] md:w-full flex-col items-start  justify-center rounded-3xl bg-accent text-white  p-16 shadow-lg shadow-red-200/50"
    >
      <div className=" flex w-full flex-col items-end justify-center gap-2">
        <label htmlFor="id_card">رقم الكارد</label>
        <input
          ref={idCard}
          className="signin-inputs max-w-60 "
          type="text"
          id="id_card"
          placeholder="رقم الكارد"
          name="id_card"
          autoComplete="on"
        />
        <p className="text-[12px] text-white ">{errors}</p>
        {
          //!-------server errors -----

          attendByCardIDErrors &&
            attendByCardIDErrors?.response?.data?.message && (
              <p className="w-full text-end text-[12px] text-white  ">
                {attendByCardIDErrors?.response?.data?.message}
              </p>
            )
        }
      </div>

      <button className=" submit max-w-60 mt-10 " type="submit">
        {attendByCardID.flag && submitLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "حضور"
        )}
      </button>
    </form>
  );
}
function MobileForm({ studentData, setStudentData }) {
  const { lectureID, groupID } = useParams();
  const [attendByMobile, setAttendByMobile] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [attendByMobileSuccess, attendByMobileErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ATTEND_STUDENT_API,
    "POST",
    attendByMobile.flag,
    attendByMobile.dependency,
    attendByMobile.data,
    true
  );
  useEffect(() => {
    if (attendByMobileSuccess) {
      setStudentData(attendByMobileSuccess?.data);
    }
  }, [attendByMobileSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });
  function onSubmit(data) {
    setAttendByMobile({
      flag: "attendByMobile",
      dependency: !attendByMobile.dependency,
      data: {
        ...data,
        type: "mobile",
        group_id: groupID,
        lecture_id: lectureID,
      },
    });
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      method="post"
      className="flex w-[450px] md:w-full flex-col items-start  justify-center rounded-3xl bg-accent text-white  p-16 shadow-lg shadow-red-200/50"
    >
      <div className=" flex w-full flex-col items-end justify-center gap-2">
        <label htmlFor="value"> رقم الهاتف </label>
        <input
          placeholder="رقم الهاتف"
          className="signin-inputs w-full "
          type="text"
          id="value"
          name="value"
          autoComplete="on"
          {...register("value", {
            required: true,
            pattern: /^[\d]{11}/,
            maxLength: 11,
          })}
        />
        {errors.value && (
          <p className="text-[12px] text-white ">
            {errors.value.type === "required" && "برجاء ادخال رقم الهاتف"}
            {errors.value.type === "pattern" && " رقم الهاتف هذا غير صحيح"}
            {errors.value.type === "maxLength" && " رقم الهاتف هذا غير صحيح"}
          </p>
        )}
        {
          //!-------server errors -----

          attendByMobileErrors &&
            attendByMobileErrors?.response?.data?.message && (
              <p className="w-full text-end text-[12px] text-white  ">
                {attendByMobileErrors?.response?.data?.message}
              </p>
            )
        }
      </div>

      <button className=" submit max-w-60 mt-10 " type="submit">
        {attendByMobile.flag && submitLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "حضور"
        )}
      </button>
    </form>
  );
}

function StudentHistoryTable({ studentHistory }) {
  const table = useTable(
    "studentHistory",
    false,
    "",
    "",
    "",
    "",
    true,
    studentHistory
  );

  return <div className="h-fit  w-full">{table}</div>;
}

function SessionStudents() {
  const AuthState = useAuthState();

  const { lectureID, groupID } = useParams();
  const [studentData, setStudentData] = useState({});
  const [reFetch, setReFetch] = useState(false);
  const [values, setValues] = useState(false);
  const [updateQuizDegree, setUpdateQuizDegree] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [updateQuizDegreeSuccess, updateQuizDegreeErrors, submitLoading] =
    useAxios(
      process.env
        .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_UPDATE_QUIZ_DEGREE_API,
      "POST",
      updateQuizDegree.flag,
      updateQuizDegree.dependency,
      updateQuizDegree.data,
      true
    );
  useEffect(() => {
    if (updateQuizDegreeSuccess) {
      setReFetch(!reFetch);
    }
  }, [updateQuizDegreeSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    values,
  });
  const [
    SessionAttendanceData,
    SessionAttendanceErrors,
    SessionAttendanceloading,
  ] = useAxios(
    `${process.env.REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_API}${lectureID}/${groupID}`,
    "GET",
    "GET",
    studentData,
    "",
    "",
    reFetch
  );
  const table = useTable(
    "sessionAttendance",
    false,
    "",
    "",
    "",
    "",
    true,
    SessionAttendanceData?.data,
    reFetch,
    setReFetch
  );
  useEffect(() => {
    if (SessionAttendanceData) {
      setValues({ degree_quiz: SessionAttendanceData?.data[0]?.quiz_degree });
    }
  }, [SessionAttendanceData]);

  function onSubmit(data) {
    setUpdateQuizDegree({
      flag: "updateQuizDegree",
      dependency: !updateQuizDegree.dependency,
      data: {
        ...data,
        group_id: groupID,
        lecture_id: lectureID,
      },
    });
  }
  return (
    <section className="flex h-full w-full flex-col items-center gap-10 overflow-y-auto px-20 py-20 sm:px-10">
      <HelmetTags title="الحضور | استاذ طارق الخطيب"></HelmetTags>

      <h2 className="mb-20 w-full text-end text-4xl font-bold">حضور الطلاب</h2>
      <div className="current-student-info flex items-center justify-center gap-10 md:flex-col">
        {studentData?.last_attendance !== 0 && studentData?.last_attendance && (
          <p>
            {studentData?.last_attendance} :
            <span className="font-semibold">اخر حضور</span>
          </p>
        )}
        {studentData?.student_name && (
          <p>
            {studentData?.student_name} :{" "}
            <span className="font-semibold">الإسم</span>
          </p>
        )}
      </div>
      {/**
       * //!StudentTable
       *
       */}
      {studentData?.history && studentData?.history?.length > 0 && (
        <StudentHistoryTable studentHistory={studentData?.history} />
      )}

      {/**
       * //!Id Card form
       *
       */}
      <CardIdForm studentData={studentData} setStudentData={setStudentData} />

      {/**
       * ////!mobile form
       *
       */}
      <MobileForm studentData={studentData} setStudentData={setStudentData} />
      <div
        className="all-students-attendance my-7 flex w-full items-center justify-between gap-10
      md:flex-col"
      >
        <Link
          to={`/admin/attendance/${groupID}/${lectureID}/absence`}
          className=" rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
        >
          الغياب{" "}
        </Link>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("attendance_view")
          ) ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("attendance_viewnumberofstudent")
          )) && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            method="post"
            className="flex w-1/2 items-start justify-end  md:w-full md:justify-center"
          >
            <button
              className=" submit max-w-60 !rounded-r-none !border-secondary  !bg-secondary !text-light hover:!bg-light hover:!text-secondary active:!scale-100"
              type="submit"
            >
              {updateQuizDegree.flag && submitLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "تعديل"
              )}
            </button>
            <div className=" flex w-full flex-col items-end justify-center gap-2">
              <input
                placeholder="درجة الإمتحان"
                className="signin-inputs !border-secondary !placeholder-secondary w-full !rounded-l-none !border-l-0"
                type="number"
                inputMode="numeric"
                id="degree_quiz"
                name="degree_quiz"
                autoComplete="on"
                {...register("degree_quiz", {
                  required: true,
                  pattern: /^[\d]/,
                  maxLength: 11,
                })}
              />
              {errors.degree_quiz && (
                <p className="text-[12px] text-white ">
                  {errors.degree_quiz.type === "required" &&
                    "برجاء ملئ هذا الحقل "}
                  {errors.degree_quiz.type === "pattern" &&
                    "يسمح فقط بلإستخدام الأرقام"}
                  {errors.degree_quiz.type === "maxLength" &&
                    "يسمح فقط بلإستخدام الأرقام"}
                </p>
              )}
              {
                //!-------server errors -----

                updateQuizDegreeErrors &&
                  updateQuizDegreeErrors?.response?.data?.message && (
                    <p className="w-full text-end text-[12px] text-white  ">
                      {updateQuizDegreeErrors?.response?.data?.message}
                    </p>
                  )
              }
            </div>
          </form>
        )}
      </div>
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default SessionStudents;
