import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useEffect, useRef } from "react";

const Question = ({ state, dispatch, homework, exam }) => {
  const { ID } = useParams();
  const essayAnswerRef = useRef();

  const checkApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_UPDATE_API
    : exam
    ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_UPDATE_API
    : process.env.REACT_APP_STUDENT_QUIZZ_UPDATE_API;

  const handleSelectAnswer = (answer, questionId, n) => {
    dispatch({
      type: "setAnsweredQuestions",
      payload: { id: questionId, order: answer, is_essay: 0, n },
    });
    async function fetchData() {
      try {
        let res = await axios.post(checkApi, {
          id: questionId,
          order: answer,
          lecture_id: ID,
          is_essay: 0,
          n: n,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }

    fetchData();

    dispatch({
      type: "setIsSelectedAnswer",
      payload: { questionId, answer, n },
    });
  };

  const handleOnBlurEssayAnswer = (questionId, n) => {
    dispatch({
      type: "setAnsweredQuestions",
      payload: {
        id: questionId,
        order: essayAnswerRef.current.value,
        is_essay: 1,
        n,
      },
    });

    async function fetchData() {
      try {
        let res = await axios.post(checkApi, {
          id: questionId,
          order: essayAnswerRef.current.value,
          lecture_id: ID,
          is_essay: 1,
          n: n,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }

    fetchData();
  };

  useEffect(() => {
    const currentAnswerd = state.answeredQuestions.filter(
      (question) =>
        question?.id === state?.questions[state.currentQuestionIndex]?.id
    )[0];
    // if not essay question set textarea by the essay question value
    if (!state?.questions[state.currentQuestionIndex]?.answers) {
      if (
        state?.questions[state.currentQuestionIndex]?.answer ||
        currentAnswerd?.order
      ) {
        essayAnswerRef.current.value = currentAnswerd?.order
          ? currentAnswerd?.order
          : state?.questions[state.currentQuestionIndex]?.answer;
      } else {
        essayAnswerRef.current.value = "";
      }
    }
  }, [state.answeredQuestions, state.currentQuestionIndex, state?.questions]);


  return (
    <div className="container max-w-[600px]">
      <div className="current-question flex-row-reverse">
        {`.${state.currentQuestionIndex + 1}`}
        <div
          dangerouslySetInnerHTML={{
            __html: state.questions[state.currentQuestionIndex]?.question,
          }}
        />
      </div>

      <div className="current-question-degree">
        <p> {state.questions[state.currentQuestionIndex]?.degree} درجه</p>
      </div>

      <div className="current-question-answers">
        {state?.questions[state.currentQuestionIndex]?.answers ? (
          <>
            {state?.questions[state.currentQuestionIndex]?.answers?.map(
              (answer, i) => (
                <div
                  key={i}
                  className={`${
                    state.questions[state.currentQuestionIndex]
                      .selected_answer === answer.order && "selected"
                  }`}
                  onClick={() =>
                    handleSelectAnswer(
                      answer.order,
                      state.questions[state.currentQuestionIndex].id,
                      state.questions[state.currentQuestionIndex].n
                    )
                  }
                >
                  <span>{answer.order} </span>
                  <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
                </div>
              )
            )}
          </>
        ) : (
          <textarea
            ref={essayAnswerRef}
            onBlur={() =>
              handleOnBlurEssayAnswer(
                state.questions[state.currentQuestionIndex].id,
                state.questions[state.currentQuestionIndex].n
              )
            }
            placeholder="اكتب الإجابة الخاصه بك"
            className="textareaQuiz !border-secondary !border-2 rounded-xl p-4 !placeholder:text-slate-400"
          ></textarea>
        )}
      </div>
    </div>
  );
};

export default Question;
