import HelmetTags from "../../../../MainComponents/HelmetTags";
import UpdateAdminImage from "./UpdateAdminImage";
import AdminChangePassword from "./AdminChangePassword";

const AdminProfile = () => {
  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="الملف الشخصي | استاذ طارق الخطيب"></HelmetTags>{" "}
      <h2 className="w-full text-end text-4xl font-bold md:text-center">
        الملف الشخصي
      </h2>
      <UpdateAdminImage />
      <AdminChangePassword />
    </section>
  );
};

export default AdminProfile;
